const onRouteUpdate = new CustomEvent('routeLoad');
const onPreRouteUpdate = new CustomEvent('routeUnload');

function tracking(location) {
  if (process.env.NODE_ENV !== `production` || typeof gtag !== `function`) {
    return null;
  }

  const pathIsExcluded = location &&
    typeof window.excludeGtagPaths !== `undefined` &&
    window.excludeGtagPaths.some((rx) => rx.test(location.pathname));

  if (pathIsExcluded) return null;

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;
    window.gtag(`event`, `page_view`, { page_path: pagePath });
  };

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }

  return null;
}

exports.onClientEntry = () => {};

exports.onPreRouteUpdate = () => window.dispatchEvent(onPreRouteUpdate);

exports.onRouteUpdate = ({ location }) => {
  window.__navigatingToLink = false;
  tracking(location);
  document.body.classList.remove('body__scroll--disabled');
  window.dispatchEvent(onRouteUpdate);
};

exports.shouldUpdateScroll = () => {
  if (window.__navigatingToLink === true) {
    return [0, 0];
  }
  return true;
};
